import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import {
  innerWidth,
  subFont,
  mainPurple,
  screen,
} from "../components/variables";

const Wrapper = styled.div`
  min-height: 60vh;
  padding: 120px 0 0 0;
  @media ${screen.xsmall} {
    padding: 160px 0 0 0;
    min-height: 70vh;
  }

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 25px;
    @media ${screen.xsmall} {
      padding: 0 40px;
    }
    @media ${screen.medium} {
      padding: 0;
    }

    .description {
      max-width: 600px;
      margin: 0 auto;
      text-align: center;

      h3 {
        font-family: ${subFont};
        font-size: 1.55rem;
        margin: 0 0 15px 0;
        @media ${screen.xsmall} {
          font-size: 1.8rem;
          margin: 0 0 20px 0;
        }
        @media ${screen.small} {
          font-size: 1.9rem;
        }
        @media ${screen.medium} {
          font-size: 2rem;
        }

        strong {
          color: ${mainPurple};
        }
      }

      p {
        font-size: 1.05rem;
        @media ${screen.xsmall} {
          font-size: 1.15rem;
        }
        @media ${screen.small} {
          font-size: 1.2rem;
        }
        @media ${screen.medium} {
          font-size: 1.25rem;
        }
      }
    }
  }
`;

const SuccessNewsletterPage = () => (
  <Layout>
    <Seo title="Thank you" />
    <Wrapper>
      <div className="inner-wrapper">
        <div className="description">
          <h3>
            Thank you for <strong>subscribing!</strong>
          </h3>
          <p>We've got your email address on the list.</p>
        </div>
      </div>
    </Wrapper>
  </Layout>
);

export default SuccessNewsletterPage;
